import { AxiosError, HttpStatusCode } from 'axios';
import { useEffect, useReducer, useState } from 'react';
import { isBrowser } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

import Required from '../../component/Required';
import Spacing from '../../component/Spacing';
import Text from '../../component/Text';
import api from '../../repository';
import c from '../../util/c';
import SearchDropdown from './Components/SearchDropdown';
import UploadImagesContainer from './Components/UploadImagesContainer';

enum PatientState {
  NOT_INQUIRY = 'NOT_INQUIRY',
  NEW_PATIENT = 'NEW_PATIENT',
  OLD_PATIENT = 'OLD_PATIENT',
}

// 에러 타입 정의
export enum FORM_ERROR {
  'AL_001' = '환부사진 접수 이력이 없는 신규 환자입니다.',
  'AL_002' = '1회 접수 시 최대 5장까지 첨부 가능합니다.',
  'AL_003' = '병원명을 입력해 주세요.',
  'AL_004' = '환자 고유번호를 입력해 주세요.',
  'AL_005' = '환자 이름을 입력해 주세요',
  'AL_006' = '환부사진을 첨부해 주세요.',
  'AL_007' = '이 환자는 하루에 접수 가능한 사진 개수를 초과했습니다.',
  'AL_008' = '입력하신 환자 고유번호와 환자 이름이 일치하지 않습니다.',
  'ERR_AFFECTED_AREA_01' = '환부사진 이미지 리스트는 빈 배열일 수 없습니다.',
  'ERR_AFFECTED_AREA_02' = '환부사진 이미지를 등록해주세요.',
  'ERR_AFFECTED_AREA_03' = '환부사진 이미지는 최대 5개까지 등록할 수 있습니다.',
  'ERR_AFFECTED_AREA_04' = '하루에 최대 50개의 환부사진 이미지를 등록할 수 있습니다.',
  'ERR_HOSPITAL_01' = '병원ID를 입력해주세요.',
  'ERR_HOSPITAL_06' = '해당 병원의 환자 고유번호를 입력해주세요.',
  'ERR_HOSPITAL_08' = '환자 이름을 입력해주세요.',
  'ERR_HOSPITAL_16' = '환자 이름이 일치하지 않습니다.',
  'ERR_HOSPITAL_15' = '존재하지 않는 환자입니다.',
  'ERR_INTERNAL_SERVER_01' = '서버 내부 에러',
  'ERR_INTERNAL_SERVER_02' = 'Presigned-URL을 생성하는 중 에러가 발생했습니다.',
  'ERR_UN_CATCHED_01' = '알 수 없는 에러가 발생했습니다.',
}

// 사진 타입 정의
type Photo = {
  file: File;
  id: string;
  preview: string;
};

// 초기 상태 타입 정의
type State = {
  hospitalName: Nullable<string>;
  hospitalId: Nullable<string>;
  patientId: Nullable<string>;
  patientName: Nullable<string>;
  birthDate: Nullable<string>;
  photos: Array<{ id: string; preview: string; fileName: string }>;
  validationMessage: FORM_ERROR | null;
  patientState: PatientState;
};

// 초기 상태
const initialState: State = {
  hospitalName: null,
  hospitalId: null,
  patientId: null,
  patientName: null,
  birthDate: null,
  photos: [],
  validationMessage: null,
  patientState: PatientState.NOT_INQUIRY,
};

// 액션 타입 정의
type Action =
  | { type: 'SET_HOSPITAL_NAME'; payload: string }
  | { type: 'SELECT_HOSPITAL_NAME'; payload: { name: string; id: string } }
  | { type: 'SET_PATIENT_ID'; payload: string }
  | { type: 'SET_PATIENT_NAME'; payload: string }
  | { type: 'SET_BIRTH_DATE'; payload: string }
  | { type: 'ADD_PHOTO'; payload: Array<{ id: string; preview: string; fileName: string }> }
  | { type: 'REMOVE_PHOTO'; payload: string }
  | { type: 'CLEAR_PATIENT_DETAILS' }
  | { type: 'SET_VALIDATION_MESSAGE'; payload: FORM_ERROR | null }
  | { type: 'SET_PATIENT_STATE'; payload: PatientState }
  | { type: 'RESET' };

// 리듀서 함수
function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'SET_HOSPITAL_NAME':
      return {
        ...state,
        hospitalName: action.payload || null,
        patientState: PatientState.NOT_INQUIRY,
        hospitalId: null,
        patientId: '',
        patientName: '',
      };
    case 'SELECT_HOSPITAL_NAME':
      return {
        ...state,
        hospitalName: action.payload.name,
        hospitalId: action.payload.id,
        patientState: PatientState.NOT_INQUIRY,
        patientId: '',
        patientName: '',
      };
    case 'SET_PATIENT_ID':
      return {
        ...state,
        patientId: action.payload || null,
        patientName: '',
        patientState: PatientState.NOT_INQUIRY,
      };

    case 'SET_PATIENT_STATE':
      return { ...state, patientState: action.payload };

    case 'SET_PATIENT_NAME':
      return { ...state, patientName: action.payload || null };

    case 'SET_BIRTH_DATE':
      return { ...state, birthDate: action.payload || null };

    case 'ADD_PHOTO': {
      if (state.photos.concat(action.payload).length > 5) return { ...state, validationMessage: FORM_ERROR.AL_002 }; // prettier-ignore
      return { ...state, photos: state.photos.concat(action.payload) };
    }

    case 'REMOVE_PHOTO':
      return {
        ...state,
        photos: state.photos.filter(({ id }) => id !== action.payload),
      };

    case 'SET_VALIDATION_MESSAGE':
      return { ...state, validationMessage: action.payload || null };

    case 'RESET':
      return initialState;

    default:
      return state;
  }
}

const INPUT_CLASSNAME = c(
  'rounded-[4px]',
  'h-[44px] w-full',
  'bg-Gray',
  'px-[17px] py-[7px]',
  'placeholder:font-Pretendard-Regular placeholder:text-[16px] placeholder:leading-[30px] placeholder:text-Gray/70',
  'font-Pretendard-Regular text-[16px] leading-[30px] text-black',
  'focus:outline-none',
);

const Search = () => {
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hospitalPatientId, setHospitalPatientId] = useState<Nullable<string>>(null);
  const [debouncedHospitalName, setDebouncedHospitalName] = useState<Nullable<string>>('');

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedHospitalName(state.hospitalName);
    }, 500); // 500ms 후에 값 업데이트

    return () => {
      clearTimeout(handler); // 이전 타이머를 취소
    };
  }, [state.hospitalName]);

  // 유효성 검사 함수
  const validateForm = (): boolean => {
    if (!state.hospitalName) {
      dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: FORM_ERROR.AL_003 });
      return false;
    }
    if (!state.patientId) {
      dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: FORM_ERROR.AL_004 });
      return false;
    }
    if (!state.patientName) {
      dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: FORM_ERROR.AL_005 });
      return false;
    }
    if (state.photos.length === 0) {
      dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: FORM_ERROR.AL_006 });
      return false;
    }
    return true;
  };

  // 사진 추가 핸들러
  const handleAddPhotos = (files: Array<{ blob: Blob; fileName: string }>) => {
    const photos = files.map(({ blob, fileName }) => ({
      id: new Date().toString(),
      fileName,
      preview: URL.createObjectURL(blob),
    }));
    dispatch({ type: 'ADD_PHOTO', payload: photos });
  };

  // 사진 삭제 핸들러
  const handleRemovePhoto = (id: string) => dispatch({ type: 'REMOVE_PHOTO', payload: id });

  // 입력 완료 핸들러
  const handleSubmit = () => {
    if (!validateForm()) return;
    dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: null });
    api.v1.users.affectedArea.images
      .POST({
        hospitalId: state.hospitalId!,
        patientName: state.patientName!,
        patientId: state.patientId,
        patientBirth: state.birthDate,
        hospitalPatientId: hospitalPatientId,
        imageKeyList: state.photos.map((photo) => photo.fileName),
      })
      .then(() => {
        navigate('/complete');
      })
      .catch((e) => {
        dispatch({
          type: 'SET_VALIDATION_MESSAGE',
          payload:
            FORM_ERROR[e.response.data.errorCode as keyof typeof FORM_ERROR] ??
            FORM_ERROR.ERR_UN_CATCHED_01,
        });
      });
  };

  const handleInquiryPatient = () => {
    api.v1.hospitals.patientId
      .GET({
        hospitalId: state.hospitalId!,
        patientId: state.patientId!,
      })
      .then((res) => {
        setHospitalPatientId(res.data.id);
        dispatch({ type: 'SET_PATIENT_STATE', payload: PatientState.OLD_PATIENT });
        dispatch({ type: 'SET_PATIENT_NAME', payload: res.data.patientName });
      })
      .catch((e) => {
        if (e instanceof AxiosError && e.response?.status === HttpStatusCode.NotFound) {
          setHospitalPatientId(null);
          dispatch({ type: 'SET_PATIENT_STATE', payload: PatientState.NEW_PATIENT });
          dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: FORM_ERROR.AL_001 });
        } else {
          dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: FORM_ERROR.ERR_UN_CATCHED_01 });
        }
      });
  };

  return (
    <form
      className={c('px-[16px]', 'h-full')}
      onSubmit={(e) => {
        e.stopPropagation();
        e.preventDefault();
        handleSubmit();
      }}
    >
      <div className='scrollbar-hidden relative h-full w-full overflow-y-auto'>
        <Spacing height='h-[44px]' />
        <div className={c('py-[12px]', 'flex justify-center')}>
          <Text
            size={20}
            lineHeight='leading-[20px]'
            color='text-[#000000]'
            weight='Bold'
          >
            환부사진 접수
          </Text>
        </div>
        <Spacing height='h-[10px]' />
        <div className={c('flex items-center justify-between')}>
          <div>
            <Text
              size={13}
              weight='SemiBold'
              color='text-[#000000]'
              lineHeight='leading-[30px]'
            >
              병원명{' '}
            </Text>
            <Required />
          </div>
          <div>
            <Required size={12} />
            <Text
              size={12}
              weight='Regular'
              color='text-[#000000]'
              lineHeight='leading-[12px]'
            >
              {' '}
              필수 입력 항목
            </Text>
          </div>
        </div>
        <div className={c('relative', 'h-[44px] w-full')}>
          <input
            className={c(INPUT_CLASSNAME, '!h-full')}
            type='text'
            placeholder='병원명을 입력해주세요'
            value={state.hospitalName ?? undefined}
            onChange={(e) => dispatch({ type: 'SET_HOSPITAL_NAME', payload: e.target.value })}
          />
          {!!debouncedHospitalName &&
            debouncedHospitalName.trim().length >= 2 &&
            !!state.hospitalName &&
            state.hospitalName.trim().length >= 2 &&
            !state.hospitalId && (
              <SearchDropdown
                search={debouncedHospitalName.trim()}
                highlight={state.hospitalName.trim()}
                onClick={(v) => dispatch({ type: 'SELECT_HOSPITAL_NAME', payload: v })}
              />
            )}
        </div>
        <Spacing height='h-[20px]' />
        <div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환자 고유번호{' '}
          </Text>
          <Required />
        </div>
        <div className={c('flex items-center space-x-[9px]')}>
          <input
            className={INPUT_CLASSNAME}
            placeholder='환자 고유번호'
            type='text'
            value={state.patientId ?? undefined}
            maxLength={20}
            onChange={(e) => dispatch({ type: 'SET_PATIENT_ID', payload: e.target.value })}
            disabled={!state.hospitalName}
          />
          <button
            className={c(
              'min-h-[44px] min-w-[50px]',
              'flex items-center justify-center',
              'flex-1',
              'font-Pretendard-SemiBold text-[16px] leading-[30px] text-white',
              'rounded-[4px] bg-blue_main_2',
            )}
            disabled={!state.patientId}
            onClick={() => handleInquiryPatient()}
            type='button'
          >
            조회
          </button>
        </div>
        <Spacing height='h-[13px]' />
        <div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환자 이름{' '}
          </Text>
          <Required />
        </div>
        <input
          className={c(
            INPUT_CLASSNAME,
            state.patientState === PatientState.OLD_PATIENT ? 'bg-[#ADADAD]' : 'bg-Gray',
          )}
          placeholder='환자 이름'
          type='text'
          value={state.patientName ?? undefined}
          maxLength={20}
          onChange={(e) => dispatch({ type: 'SET_PATIENT_NAME', payload: e.target.value })}
          disabled={state.patientState === PatientState.OLD_PATIENT}
        />
        <Spacing height='h-[13px]' />
        <Text
          size={13}
          weight='SemiBold'
          color='text-[#000000]'
          lineHeight='leading-[30px]'
        >
          환자 생년월일
        </Text>
        <input
          className={INPUT_CLASSNAME}
          placeholder='환자 생년월일(19300101)'
          type='text'
          value={state.birthDate ?? undefined}
          maxLength={8}
          onChange={(e) => {
            const num = Number(e.target.value);
            if (isNaN(num)) return;
            dispatch({ type: 'SET_BIRTH_DATE', payload: e.target.value });
          }}
        />
        <Spacing height='h-[14px]' />
        <div>
          <Text
            size={13}
            weight='SemiBold'
            color='text-[#000000]'
            lineHeight='leading-[30px]'
          >
            환부사진(가로, 최대 5장){' '}
          </Text>
          <Required />
        </div>
        <Spacing height='h-[2px]' />
        <UploadImagesContainer
          photos={state.photos}
          handleAddPhotos={handleAddPhotos}
          handleRemovePhoto={handleRemovePhoto}
          handleValidateMessage={(message) =>
            dispatch({ type: 'SET_VALIDATION_MESSAGE', payload: message })
          }
        />
        <Spacing height='h-[9px]' />
        <div className={c('h-[20px] w-full', 'flex justify-center')}>
          <Text
            size={13}
            weight='Medium'
            color='text-ERROR'
            lineHeight='leading-[20px]'
            className='h-[20px]'
          >
            {state.validationMessage}
          </Text>
        </div>
        <Spacing height='h-[84px]' />
      </div>
      <div
        className={c(
          'fixed bottom-0 left-1/2 -translate-x-[50%]',
          'px-[16px] pb-[20px]',
          isBrowser ? 'w-[375px]' : 'w-full',
          'bg-[#ffffff]',
        )}
      >
        <button
          type='submit'
          className={c(
            'h-[54px] w-full',
            'flex items-center justify-center',
            'font-Pretendard-SemiBold text-[16px] leading-[30px] text-white',
            'rounded-[8px] bg-blue_main_2',
          )}
        >
          입력 완료
        </button>
      </div>
    </form>
  );
};

export default Search;
