import hospitals from './hospitals';
import users from './users';

const api = {
  v1: {
    hospitals,
    users,
  },
};

export default api;
