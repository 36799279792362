import { PropsWithChildren } from 'react';
import { isBrowser } from 'react-device-detect';

import c from './util/c';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <div className={c('h-[100svh] w-full bg-Gray', 'flex justify-center')}>
      <div className={c('h-full', 'bg-[#ffffff]', isBrowser ? 'w-[375px]' : 'w-full')}>
        {children}
      </div>
    </div>
  );
};

export default Layout;
