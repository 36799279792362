import type { PropsWithChildren } from 'react';

import c from '../util/c';

const Text = ({
  color,
  weight,
  size,
  children,
  truncate,
  whiteSpace,
  lineHeight,
  align,
  wordBreak,
  className,
  cancelLine = false,
  clamp,
}: PropsWithChildren<{
  align?: keyof typeof TEXT_ALIGN;
  color: `text-${string}`;
  weight: 'Bold' | 'SemiBold' | 'Regular' | 'Medium';
  size: 12 | 13 | 14 | 16 | 18 | 20 | 22 | 24 | 26 | 28 | 30 | 32 | 34 | 36;
  lineHeight: `leading-[${number}px]`;
  truncate?: boolean;
  whiteSpace?: keyof typeof WHITE_SPACE;
  wordBreak?: keyof typeof WORD_BREAK;
  className?: string;
  cancelLine?: boolean;
  clamp?: 1 | 2 | 3 | 4 | 5 | 6;
}>) => {
  return (
    <span
      className={c(
        color,
        className,
        align && TEXT_ALIGN[align],
        WEIGHT_CLASSNAME[weight],
        truncate && 'w-full truncate',
        SIZE_CLASSNAME[size],
        lineHeight,
        whiteSpace && WHITE_SPACE[whiteSpace],
        wordBreak && WORD_BREAK[wordBreak],
        cancelLine && 'line-through',
        clamp && LINE_CLAMP[clamp],
      )}
      title={typeof children === 'string' ? children : ''}
    >
      {children}
    </span>
  );
};

export default Text;

const WEIGHT_CLASSNAME: Record<
  Parameters<typeof Text>[0]['weight'],
  `font-Pretendard-${Parameters<typeof Text>[0]['weight']}`
> = {
  Bold: 'font-Pretendard-Bold',
  SemiBold: 'font-Pretendard-SemiBold',
  Medium: 'font-Pretendard-Medium',
  Regular: 'font-Pretendard-Regular',
};

const SIZE_CLASSNAME: Record<
  Parameters<typeof Text>[0]['size'],
  `text-[${Parameters<typeof Text>[0]['size']}px]`
> = {
  12: 'text-[12px]',
  13: 'text-[13px]',
  14: 'text-[14px]',
  16: 'text-[16px]',
  18: 'text-[18px]',
  20: 'text-[20px]',
  22: 'text-[22px]',
  24: 'text-[24px]',
  26: 'text-[26px]',
  28: 'text-[28px]',
  30: 'text-[30px]',
  32: 'text-[32px]',
  34: 'text-[34px]',
  36: 'text-[36px]',
};
const WHITE_SPACE = {
  normal: 'whitespace-normal',
  nowrap: 'whitespace-nowrap',
  pre: 'whitespace-pre',
  preLine: 'whitespace-pre-line',
  preWrap: 'whitespace-pre-wrap',
  breakSpaces: 'whitespace-break-spaces',
};

const TEXT_ALIGN = {
  justify: 'text-justify',
  left: 'text-left',
  right: 'text-right',
  center: 'text-center',
  end: 'text-end',
  start: 'text-start',
};
const WORD_BREAK = {
  normal: 'break-normal',
  words: 'break-words',
  all: 'break-all',
  keep: 'break-keep',
};

const LINE_CLAMP = {
  1: 'line-clamp-1 break-all',
  2: 'line-clamp-2 break-all w-full',
  3: 'line-clamp-3 break-all w-full',
  4: 'line-clamp-4 break-all w-full',
  5: 'line-clamp-5 break-all w-full',
  6: 'line-clamp-6 break-all w-full',
};
