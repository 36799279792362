import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';
import ReactDOM from 'react-dom/client';
import Modal from 'react-modal';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import routes from './routes';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } });

const appElement = document.getElementById('root') as HTMLElement;
Modal.setAppElement(appElement);

ReactDOM.createRoot(appElement).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={createBrowserRouter(routes)} />
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
);
