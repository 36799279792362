import React, { Suspense, useEffect } from 'react';
import { Outlet, RouteObject } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Layout from './Layout';
import Complete from './pages/Complete';
import Search from './pages/Search';

const ScrollToTop = () => {
  const { pathname, search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, search]);
  return null;
};

const routes: RouteObject[] = [
  {
    path: '*',
    element: (
      <Layout>
        <ScrollToTop />
        <Suspense>
          <Outlet />
        </Suspense>
      </Layout>
    ),
    children: [
      { path: '', element: <Search /> },
      { path: 'complete', element: <Complete /> },
    ],
  },
];

export default routes;
