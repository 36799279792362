import { useInfiniteQuery } from '@tanstack/react-query';
import React, { useCallback, useRef } from 'react';

import SwitchCase from '../../../component/SwitchCase';
import Text from '../../../component/Text';
import api from '../../../repository';
import c from '../../../util/c';

const SearchDropdown = ({
  search,
  highlight,
  onClick,
}: {
  search: string;
  highlight: string;
  onClick: (v: { name: string; id: string }) => void;
}) => {
  const observer = useRef<IntersectionObserver | null>(null);
  const cursorRef = useRef<string | undefined>(undefined);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isFetching } = useInfiniteQuery({
    queryKey: ['api.v1.hospitals.GET', search],
    queryFn: () =>
      api.v1.hospitals.GET({ name: search, cursor: cursorRef.current, limit: 10 }).then((res) => {
        cursorRef.current = res.data.meta.nextCursor;
        return res;
      }),
    getNextPageParam: (lastPage) => (lastPage.data.meta.hasNextPage ? 1 : undefined),
    initialPageParam: 1,
  });

  const lastHospitalRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isFetchingNextPage) return; // 로딩 중일 때는 무시

      if (observer.current) observer.current.disconnect();

      // IntersectionObserver 생성
      observer.current = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasNextPage && !isFetching) {
            fetchNextPage(); // 마지막 항목이 보이면 추가 데이터를 불러옴
          }
        },
        { threshold: 1.0 }, // 100%가 화면에 보여야 트리거됨
      );

      if (node) observer.current.observe(node);
    },
    [isFetchingNextPage, hasNextPage, fetchNextPage],
  );

  const hospitals = data?.pages.flatMap((page) => page.data.items);
  if (!hospitals) return null;
  if (hospitals.length === 0)
    return (
      <div
        className={c(
          'h-[73px] w-full px-[17px]',
          'flex items-center',
          'absolute left-0 top-[49px]',
          'shadow-1',
          'bg-[#F2F4F5]',
          'z-10',
          'rounded-[4px]',
        )}
      >
        <Text
          size={16}
          weight='Regular'
          lineHeight='leading-[16px]'
          color='text-Gray/70'
        >
          검색된 병원명이 없습니다
        </Text>
      </div>
    );
  return (
    <div
      className={c(
        'h-fit max-h-[292px] w-full py-[10px] pr-[5px]',
        'absolute left-0 top-[49px]',
        'shadow-1',
        'bg-[#F2F4F5]',
        'z-10',
        'rounded-[4px]',
      )}
    >
      <ul className={c('max-h-[272px] w-full overflow-y-auto', 'Dropdown_Scrollbar')}>
        {hospitals.map((hospital) => (
          <HospitalInfo
            onClick={onClick}
            key={hospital.id}
            highlight={highlight}
            {...hospital}
          />
        ))}
        {hasNextPage && (
          <div
            ref={lastHospitalRef}
            className='h-[1px]'
          />
        )}
      </ul>
    </div>
  );
};

export default SearchDropdown;

const HospitalInfo = ({
  id,
  name,
  address,
  highlight,
  onClick,
}: {
  id: string;
  name: string;
  highlight: string;
  address: string;
  onClick: (v: { name: string; id: string }) => void;
}) => {
  const parts = name.split(highlight); // name을 highlight 기준으로 분리

  return (
    <li className={c('min-h-[73px] w-full', 'p-[17px]')}>
      <button
        onClick={() => onClick({ name, id })}
        className={c('flex flex-col space-y-[8px]')}
      >
        <Text
          size={16}
          weight='Regular'
          color='text-black'
          lineHeight='leading-[19.5px]'
          align='start'
          clamp={2}
        >
          {parts.map((part, index) => (
            <React.Fragment key={index}>
              {part}
              {index < parts.length - 1 && (
                <Text
                  size={16}
                  weight='Bold'
                  color='text-black'
                  lineHeight='leading-[19.5px]'
                >
                  {highlight}
                </Text>
              )}
            </React.Fragment>
          ))}
        </Text>
        <Text
          size={13}
          weight='Regular'
          color='text-Gray/70'
          lineHeight='leading-[19.5px]'
          align='start'
          clamp={2}
        >
          {address}
        </Text>
      </button>
    </li>
  );
};
