import Text from './Text';

const Required = ({ size = 13 }: { size?: Parameters<typeof Text>[0]['size'] }) => {
  return (
    <Text
      size={size}
      weight='SemiBold'
      lineHeight='leading-[30px]'
      color='text-[#FF0000]'
    >
      *
    </Text>
  );
};

export default Required;
