import axios from '../axios';
import { PostUsersAffectedAreaImagesReq } from './req';
import { PostUsersAffectedAreaImagesRes, PostUsersAffectedAreaPresignedUrlRes } from './res';

const users = {
  affectedArea: {
    presignedUrl: {
      POST: async () =>
        (
          await axios.post<PostUsersAffectedAreaPresignedUrlRes>(
            '/api/v1/users/affected-area/presigned-url',
          )
        ).data,
    },
    images: {
      POST: async (body: PostUsersAffectedAreaImagesReq) =>
        (
          await axios.post<PostUsersAffectedAreaImagesRes>(
            '/api/v1/users/affected-area/images',
            body,
          )
        ).data,
    },
  },
};

export default users;
