import axios from '../axios';
import { GetHospitalsPatientIdReq, GetHospitalsReq } from './req';
import { GetHospitalsPatientIdRes, GetHospitalsRes } from './res';

const hospitals = {
  GET: async (params: GetHospitalsReq) =>
    (await axios.get<GetHospitalsRes>('/api/v1/hospitals', { params })).data,
  patientId: {
    GET: async (params: GetHospitalsPatientIdReq) =>
      (await axios.get<GetHospitalsPatientIdRes>(`/api/v1/hospitals/patinet-id`, { params })).data,
  },
};

export default hospitals;
